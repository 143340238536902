import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import Layout from "../components/layout"
import ContentDiv from "../components/contentDiv"
import { Helmet } from "react-helmet"
import Text, { TextType } from "../components/text"
import styled from "styled-components"
import { tablet } from "../util/mediaQueries"
import PageImage from "../components/pageImage"
import SEO from "../components/seo"
import { Media } from "gatsby-plugin-fresnel"
import FirstSection from "../components/firstSection"

const HeaderDiv = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 70px;
  padding-bottom: 70px;
`

const Steuernews = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.deubner-online.de/commons/js/iframe/iframeDynHeight.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <Layout>
            <SEO lang="de" title="Steuernews" />
            <PageImage>

                <Media greaterThanOrEqual="md">
                    <StaticImage


                        src="../images/Titelbild_Steuernews_PC.jpeg"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Image"
                        objectFit="contain"
                        layout="fullWidth"
                    />
                </Media>
                <Media at="sm">
                    <StaticImage


                        src="../images/Titelbild_Steuernews_Smartp.jpeg"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Image"
                        objectFit="contain"
                        layout="fullWidth"
                    />
                </Media>
            </PageImage>

            <FirstSection headerText="Steuernews" >
                Immer aktuell mit unseren Steuernews:
                Erfahren Sie laufend aktuelle Informationen sowie steuerliche und gesetzliche Neuerungen!
            </FirstSection>
            <ContentDiv>
                <Helmet>
                    <script type="text/javascript">var K='107802'</script>
                    <script src="https://107802.mainfo.net/erv.js" defer type="text/javascript" />
                </Helmet>

                <div id="myContent"></div>
            </ContentDiv>
        </Layout>)

}

export default Steuernews
